<template>
  <div>
    <div class="row mb-1 d-flex justify-content-between">
      <div class="col-12 col-xl-2 col-md-2 mb-1">
        <b-button
          variant="success"
          block
          @click="openModal('add')"
        >
          เพิ่ม
        </b-button>
      </div>
      <div class="col-12 col-xl-3 col-md-5">
        <b-form-group
          style="margin-bottom: 0rem;"
        >
          <div class="d-flex align-items-center">
            <label class="mr-1">ค้นหา</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="ค้นหา"
              type="text"
              class="d-inline-block mr-1"
            />
          </div>
        </b-form-group>
      </div>
    </div>
    <b-table
      striped
      hover
      responsive
      class="type-relative"
      :per-page="options.perPage"
      :current-page="options.currentPage"
      :items="listAccount"
      :fields="fields"
      :filter="searchTerm"
      show-empty
      @filtered="onFiltered"
    >
      <template #cell(fullname)="data">
        {{ data.item.firstName }} {{ data.item.lastName }}
      </template>
      <template #cell(fullcontact)="data">
        {{ data.item.phoneNumber }}
      </template>
      <template #cell(bankaccountName)="data">
        <b-img
          :src="require('@/assets/images/bank/' + data.item.bankaccountName + '.png')"
          :width="30"
        />
        {{ data.item.bankaccountName }}
      </template>
      <template #cell(manage)="data">
        <div class="d-grid d-md-block">
          <button
            class="btn btn-warning mr-1"
            type="button"
            @click="openModal('edit',data.item,data.item._id)"
          >
            แก้ไข
          </button>
          <button
            class="btn btn-danger mr-1"
            type="button"
            @click="removeAccount(data.item._id)"
          >
            ลบ
          </button>
          <b-button
            variant="info"
            class="mr-1"
            @click="openModalCredit(data.item._id)"
          >
            <feather-icon icon="CreditCardIcon" />
          </b-button>
        </div>
      </template>
      <template #empty>
        <div class="text-center m-1">
          <h4>
            ไม่พบข้อมูลผู้ใช้งาน
          </h4>
        </div>
      </template>
      <template #emptyfiltered>
        <div class="text-center m-1">
          <h4>
            ไม่พบข้อมูลผู้ใช้งาน
          </h4>
        </div>
      </template>
    </b-table>
    <b-card-body
      v-if="totalItem > 0"
      class="d-flex justify-content-between flex-wrap pt-0 mt-2"
    >

      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="options.perPage"
          size="sm"
          inline
          :options="options.pageOptions"
        />
      </b-form-group>
      <b-pagination
        v-model="options.currentPage"
        :total-rows="options.totalRows"
        :per-page="options.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-body>
    <hr>
    <div class="mb-2 mt-2">
      <h3>รอดำเนินการ</h3>
    </div>
    <div class="row d-flex align-items-center justify-content-end mb-1">
      <div class="col-12 col-xl-3 col-md-5">
        <b-form-group style="margin-bottom: 0rem;">
          <div class="d-flex align-items-center">
            <label class="mr-1">ค้นหา</label>
            <b-form-input
              v-model="searchTerm2"
              placeholder="ค้นหา"
              type="text"
              class="d-inline-block mr-1"
            />
          </div>
        </b-form-group>
      </div>
    </div>
    <b-table
      striped
      hover
      sticky-header
      responsive
      class="type-relative"
      :per-page="options2.perPage"
      :current-page="options2.currentPage"
      :items="listAccount2"
      :fields="fields2"
      :filter="searchTerm2"
      show-empty
      @filtered="onFiltered2"
    >
      <template #cell(fullname)="data">
        {{ data.item.firstName }} {{ data.item.lastName }}
      </template>
      <template #cell(fullcontact)="data">
        {{ data.item.phoneNumber }}
      </template>
      <template #cell(bankaccountName)="data">
        <b-img
          v-if="data.item.bankaccountName !== undefined"
          :src="require('@/assets/images/bank/' + data.item.bankaccountName + '.png')"
          :width="30"
        />
        {{ data.item.bankaccountName }}
      </template>
      <template #cell(manage)="data">
        <div class="d-grid d-md-block">
          <b-button
            class="mr-1"
            variant="warning"
            @click="openModal('edit',data.item,data.item._id)"
          >
            แก้ไข
          </b-button>
          <b-button
            class="mr-1"
            variant="success"
            @click="onConfirm(data.item._id)"
          >
            <feather-icon icon="UserCheckIcon" />
          </b-button>
          <b-button
            variant="danger"
            class="mr-1"
            @click="onCancel(data.item._id)"
          >
            <feather-icon icon="UserMinusIcon" />
          </b-button>
        </div>
      </template>
      <template #empty>
        <div class="text-center m-1">
          <h4>
            ไม่พบข้อมูลผู้ใช้งาน
          </h4>
        </div>
      </template>
      <template #emptyfiltered>
        <div class="text-center m-1">
          <h4>
            ไม่พบข้อมูลผู้ใช้งาน
          </h4>
        </div>
      </template>
    </b-table>
    <b-card-body
      v-if="totalItem2 > 0"
      class="d-flex justify-content-between flex-wrap pt-0"
    >

      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="options2.perPage"
          size="sm"
          inline
          :options="options2.pageOptions"
        />
      </b-form-group>
      <b-pagination
        v-model="options2.currentPage"
        :total-rows="options2.totalRows"
        :per-page="options2.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-body>
    <modal-account
      :id="userid"
      ref="addaccount"
      :title="typeModal"
      @setCloseModal="setCloseModal"
      @LoadList="LoadList"
    />
    <modal-credit
      :id="userid"
      ref="creditmodal"
    />
  </div>
</template>
<script>
import {
  BCardBody, BPagination, BFormSelect, BTable, BFormGroup, BButton, BFormInput, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalAccount from './addAccount.vue'
import ModalCredit from './modalCredit.vue'

export default {
  components: {
    BCardBody,
    BPagination,
    BFormSelect,
    BFormGroup,
    BButton,
    BFormInput,
    BTable,
    ModalAccount,
    BImg,
    ModalCredit,
  },
  data() {
    return {
      userid: '',
      listAccount: [],
      listAccount2: [],
      searchTerm: '',
      searchTerm2: '',
      fields: [
        {
          key: 'username',
          label: 'รหัสผู้ใช้งาน',
          sortable: true,
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:100px',
        },
        {
          key: 'fullname',
          label: 'ชื่อจริง - นามสกุล',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:200px',
        },
        {
          key: 'fullcontact',
          label: 'เบอร์โทรศัพท์',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:140px',
        },
        {
          key: 'bankaccountName',
          label: 'ธนาคาร',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:150px',
        },
        {
          key: 'bankaccountNumber',
          label: 'หมายเลขบัญชี',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:150px',
        },
        {
          key: 'manage',
          label: 'จัดการ',
          thClass: 'bg-primary text-white',
          thStyle: 'min-width:300px',
        },
      ],
      fields2: [
        {
          key: 'username',
          label: 'รหัสผู้ใช้งาน',
          sortable: true,
          thClass: 'bg-danger text-white',
          thStyle: 'min-width:100px',
        },
        {
          key: 'fullname',
          label: 'ชื่อจริง - นามสกุล',
          thClass: 'bg-danger text-white',
          thStyle: 'min-width:200px',
        },
        {
          key: 'fullcontact',
          label: 'เบอร์โทรศัพท์',
          thClass: 'bg-danger text-white',
          thStyle: 'min-width:140px',
        },
        {
          key: 'bankaccountName',
          label: 'ธนาคาร',
          thClass: 'bg-danger text-white',
          thStyle: 'min-width:150px',
        },
        {
          key: 'bankaccountNumber',
          label: 'หมายเลขบัญชี',
          thClass: 'bg-danger text-white',
          thStyle: 'min-width:150px',
        },
        {
          key: 'manage',
          label: 'จัดการ',
          thClass: 'bg-danger text-white',
          thStyle: 'min-width:300px',
        },
      ],
      options: {
        perPage: 6,
        currentPage: 1,
        pageOptions: [6, 12, 24, 100],
        totalRows: 6,
      },
      totalItem: 0,
      options2: {
        perPage: 6,
        currentPage: 1,
        pageOptions: [6, 12, 24, 100],
        totalRows: 6,
      },
      totalItem2: 0,
      typeModal: '',
      isOpen: false,
    }
  },
  computed: {
    checkEmptyTable1() {
      return this.listAccount.length > 0
    },
    checkEmptyTable2() {
      return this.listAccount2.length > 0
    },
  },
  created() {
    this.LoadList()
  },
  methods: {
    onFiltered(FilterItem) {
      this.options.totalRow = FilterItem.length
      this.options.currentPage = 1
    },
    onFiltered2(FilterItem) {
      this.options2.totalRow = FilterItem.length
      this.options2.currentPage = 1
    },
    setCloseModal() {
      this.userid = ''
    },
    async LoadList() {
      try {
        const result = await this.$store.dispatch('account/getAllAccount')
        const result2 = await this.$store.dispatch('account/getAllAccountFail')
        this.listAccount = result.data.items
        this.totalItem = result.data.totalItem
        this.options.totalRows = result.data.totalItem
        this.listAccount2 = result2.data.items
        this.totalItem2 = result2.data.totalItem
        this.options2.totalRows = result2.data.totalItem
        console.log(result)
        console.log(result2)
      } catch (error) {
        console.log(error)
      }
    },
    removeAccount(id) {
      if (id !== '' && id !== undefined) {
        this.$swal({
          title: 'คุณต้องการจะลบข้อมูลนี้?',
          text: 'คุณมั่นใจรึเปล่า ? คุณจะไม่สามารถนำข้อมูลนี้กลับมาได้!',
          type: 'warning',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn-success mr-1',
          confirmButtonText: 'ยืนยัน',
          cancelButtonClass: 'btn-danger',
          cancelButtonText: 'ยกเลิก',
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('account/removeAccount', { id }).then(response => {
              if (response.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
                this.LoadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
              }
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.message,
                },
              })
            })
          }
        })
      }
    },
    openModal(name, data, id) {
      let filter = ''
      if (name === 'add') {
        filter = 'เพิ่มข้อมูลสมาชิก'
      } else if (name === 'edit') {
        filter = 'แก้ไขข้อมูลสมาชิก'
        this.userid = id
      }
      this.typeModal = filter
      this.$refs.addaccount.show()
    },
    closeModal() {
      this.$refs.addaccount.close()
    },
    onConfirm(id) {
      this.$store.dispatch('account/confirmAccount', { id }).then(response => {
        if (response.data.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'สมาชิก',
              icon: 'UserIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.LoadList()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'สมาชิก',
              icon: 'UserIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
        }
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'สมาชิก',
            icon: 'UserIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      })
    },
    onCancel(id) {
      this.$swal({
        title: 'คุณต้องการจะลบข้อมูลนี้?',
        text: 'คุณมั่นใจรึเปล่า ? คุณจะไม่สามารถนำข้อมูลนี้กลับมาได้!',
        type: 'warning',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn-success mr-1',
        confirmButtonText: 'ยืนยัน',
        cancelButtonClass: 'btn-danger',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('account/cancelAccount', { id }).then(response => {
            if (response.data.success) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'สมาชิก',
                  icon: 'UserIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.LoadList()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'สมาชิก',
                  icon: 'UserIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'สมาชิก',
                icon: 'UserIcon',
                variant: 'danger',
                text: error.message,
              },
            })
          })
        }
      })
    },
    openModalCredit(id) {
      this.userid = id
      this.$refs.creditmodal.show()
    },
    closeModalCredit() {
      this.$refs.creditmodal.close()
    },
  },
}
</script>
<style scoped>
.table-height {
  min-height:395px;
}
</style>="">
