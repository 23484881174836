<template>
  <div>
    <b-modal
      v-model="openModal"
      centered
      header-bg-variant="primary"
      hide-footer
    >
      <h4>
        {{ title }}
      </h4>

      <validation-observer
        ref="accountRules"
      >
        <validation-provider
          v-if="title === 'เพิ่มข้อมูลสมาชิก'"
          #default="{ errors }"
          name="telephoneNumber"
          rules="numeric"
        >
          <div
            class="col-md-12 mt-2"
          >
            <label>เบอร์โทรศัพท์</label>
            <b-form-input
              id="telephoneNumber"
              v-model="input.telephoneNumber"
              :state="errors.length > 0 ? false:null"
              autofocus
              placeholder="เบอร์โทรศัพท์"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกเบอร์โทรศัพท์</small>
        </validation-provider>
        <validation-provider
          v-if="title === 'เพิ่มข้อมูลสมาชิก'"
          #default="{ errors }"
          name="password"
          rules="required"
        >
          <div
            class="col-md-12 mt-1"
          >
            <label>Password</label>
            <b-form-input
              id="password"
              v-model="input.password"
              type="password"
              :state="errors.length > 0 ? false:null"
              placeholder="Password"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอก Password</small>
        </validation-provider>
        <validation-provider
          v-if="title === 'เพิ่มข้อมูลสมาชิก'"
          #default="{ errors }"
          name="password"
          rules="required"
        >
          <div
            class="col-md-12 mt-1"
          >
            <label>Line</label>
            <b-form-input
              id="line"
              v-model="input.line"
              type="text"
              :state="errors.length > 0 ? false:null"
              placeholder="Line"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกไลน์</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="firstname"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>ชื่อจริง</label>
            <b-form-input
              id="firstname"
              v-model="input.firstName"
              :state="errors.length > 0 ? false:null"
              placeholder="ชื่อจริง"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกชื่อจริง</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="lastname"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>นามสกุล</label>
            <b-form-input
              id="lastname"
              v-model="input.lastName"
              :state="errors.length > 0 ? false:null"
              placeholder="นามสกุล"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกนามสกุล</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="bankname"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>ธนาคาร</label>
            <b-form-select
              v-model="input.bankaccountName"
              :options="options"
              :state="errors.length > 0 ? false:null"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณาเลือกธนาคาร</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="banknumber"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>เลขบัญชี </label>
            <b-form-input
              id="banknumber"
              v-model="input.bankaccountNumber"
              :state="errors.length > 0 ? false:null"
              placeholder="เลขบัญชี"
              type="number"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกเลขบัญชี</small>
        </validation-provider>
      </validation-observer>
      <div class="row mt-2 d-flex justify-content-end">
        <b-button
          variant="danger"
          class="mr-1"
          @click="close"
        >
          ยกเลิก
        </b-button>
        <b-button
          v-if="title === 'เพิ่มข้อมูลสมาชิก'"
          variant="success"
          class="mr-1"
          :disabled="isConfirm"
          @click="addAccount"
        >
          <b-spinner
            v-if="isConfirm"
            small
          /> ยืนยัน
        </b-button>
        <b-button
          v-if="title === 'แก้ไขข้อมูลสมาชิก'"
          variant="warning"
          class="mr-1"
          :disabled="isConfirm"
          @click="editAccount"
        >
          <b-spinner
            v-if="isConfirm"
            small
          /> แก้ไข
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BFormInput, BFormSelect, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      openModal: false,
      input: {
        id: '',
        firstName: '',
        lastName: '',
        bankaccountNumber: '',
        bankaccountName: null,
        line: '',
        username: '',
        password: '',
        telephoneNumber: '',
      },
      options: [
        { value: null, text: 'กรุณาเลือกบัญชีธนาคาร' },
        { value: 'KBANK', text: 'ธนาคารกสิกร' },
        { value: 'SCB', text: 'ธนาคารไทยพาณิชย์' },
        { value: 'KTB', text: 'ธนาคารกรุงไทย' },
        { value: 'TMB', text: 'ธนาคารทหารไทย' },
        { value: 'GSB', text: 'ธนาคารออมสิน' },
        { value: 'NBANK', text: 'ธนาคารธนชาติ' },
      ],
      isConfirm: false,
      required,
      numeric,
    }
  },
  watch: {
  },
  beforeUpdate() {
    if (this.id !== '') {
      this.getinfo()
    }
  },
  methods: {
    show() {
      this.openModal = true
    },
    close() {
      this.openModal = false
    },
    addAccount() {
      this.isConfirm = true
      this.$refs.accountRules.validate().then(success => {
        if (success) {
          const payload = {
            firstName: this.input.firstName,
            lastName: this.input.lastName,
            bankaccountNumber: this.input.bankaccountNumber,
            bankaccountName: this.input.bankaccountName,
            telephoneNumber: this.input.telephoneNumber,
            password: this.input.password,
            line: this.input.line,
          }
          this.$store.dispatch('account/addAccount', payload).then(response => {
            if (response.data.success) {
              // console.log('success')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'สมาชิก',
                  icon: 'UserIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.close()
              this.$emit('setCloseModal')
              this.$emit('LoadList')
              this.defaultData()
            } else {
              // console.log('fail')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'สมาชิก',
                  icon: 'UserIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.isConfirm = false
            }
          })
        } else {
          this.isConfirm = false
        }
      })
    },
    editAccount() {
      this.isConfirm = true
      this.$refs.accountRules.validate().then(success => {
        if (success) {
          const payload = {
            id: this.input.id,
            firstName: this.input.firstName,
            lastName: this.input.lastName,
            bankaccountNumber: this.input.bankaccountNumber,
            bankaccountName: this.input.bankaccountName,
            line: this.input.line,
          }
          this.$store.dispatch('account/editAccount', payload).then(response => {
            if (response.data.success) {
              // console.log('success')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'สมาชิก',
                  icon: 'UserIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.close()
              this.$emit('setCloseModal')
              this.$emit('LoadList')
              this.defaultData()
            } else {
              // console.log('fail')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'สมาชิก',
                  icon: 'UserIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.isConfirm = false
            }
          })
        } else {
          this.isConfirm = false
        }
      })
    },
    getinfo() {
      this.$store.dispatch('account/getAccountbyId', { id: this.id }).then(response => {
        console.log(response)
        this.input.id = this.id
        this.input.firstName = response.data.item.firstName
        this.input.lastName = response.data.item.lastName
        this.input.bankaccountNumber = response.data.item.bankaccountNumber
        this.input.bankaccountName = response.data.item.bankaccountName
      })
    },
    defaultData() {
      this.input.id = ''
      this.input.firstName = ''
      this.input.lastName = ''
      this.input.bankaccountNumber = ''
      this.input.bankaccountName = null
      this.isConfirm = false
    },
  },
}
</script>
