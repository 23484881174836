<template lang="">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body h-100">
        <div class="mb-2">
          <h3>
            จัดการผู้ใช้งาน
          </h3>
          <div class="separator" />
        </div>
        <list-account />

      </div>

    </div>

  </div>
</template>
<script>
// import {
//   BFormGroup, BButton, BFormInput,
// } from 'bootstrap-vue'
import ListAccount from './listAccount.vue'

export default {
  components: {
    ListAccount,
  },
  data() {
    return {

    }
  },
}
</script>
<style lang="">

</style>
