<template>
  <div>
    <b-modal
      v-model="openModal"
      centered
      header-bg-variant="primary"
      hide-footer
    >
      <div class="row">
        <div class="col-12 text-center">
          <h4>
            {{ title }}
          </h4>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 text-center">
          <p
            style="font-size:30px; font-weight:bolder;"
            class="text-center"
          >
            {{ credit | toCurrencyBath }}
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: 'ยอดเครดิต',
      credit: 0,
      openModal: false,
    }
  },
  beforeUpdate() {
    if (this.id !== '') {
      this.getCredit()
    }
  },
  methods: {
    show() {
      this.openModal = true
    },
    close() {
      this.openModal = false
    },
    getCredit() {
      this.$store.dispatch('transaction/checkCredit', { userId: this.id }).then(response => {
        this.credit = response.data.credit
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>
